@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.page-wrapper {
  @include mainContainerStyles;
  max-width: 100%;
  align-items: center;
  @include containerPaddings;
  padding-top: 110px;

  @include min-428-break {
    padding-top: 130px;
  }
}

.pet {
  @include mainContainerStyles;

  .item-block-white {
    background: var(--cst-content-background);
    display: flex;
    flex-direction: column;

    @include min-744-break {
      gap: 16px;
    }
  }

  .item-block-props {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include min-744-break {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  .items-block,
  .description {
    @include min-744-break {
      padding: 14px 28px;
      @include mainContentStyles;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: var(--elevation-1);
    @include min-428-break {
      gap: 14px;
    }

    @include min-744-break {
      display: grid;
      width: 100%;
      grid-template-columns: minmax(340px, 1fr) minmax(324px, 1fr);
      grid-template-rows: 75px minmax(181px, 1fr);
      column-gap: 24px;
      row-gap: 16px;

      .diagram {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
  }
  .items-block {
    @include min-744-break {
      display: grid;
      width: 100%;
      grid-template-columns: minmax(340px, 340px) minmax(324px, 1fr);
      grid-template-rows: 52px minmax(248px, 1fr);
      column-gap: 24px;
      row-gap: 12px;
    }
  }
}
