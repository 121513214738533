@use '@fixed-size' as *;
@use '@queries' as *;

.container {
  padding: 18px 0 60px 0px;

  @include min-1920-break {
    padding: 24px 0 60px 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-height: max-content;
  width: 100%;

  @include min-1440-break {
    gap: 24px;
  }

  .top-slot {
    display: flex;
    gap: 20px;
    max-height: max-content;

    justify-content: space-between;

    @include min-1920-break {
      gap: 32px;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 26px;
      width: max-content;

      @include min-1440-break {
        gap: 24px;
      }

      @include min-1920-break {
        gap: 48px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      gap: 14px;
      @include fixedSize(340px, max-content);

      @include min-1440-break {
        @include fixedSize(369px, max-content);
      }

      @include min-1920-break {
        @include fixedSize(408px, max-content);
      }
    }
  }
}
